import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { Client } from "../../types";

import { useNotify } from "../../contexts/NotifyContext";
import { PencilIcon } from "../../shared-components/utils/icons";
import { ModalInfoCompany } from "../../components/ModalInfoCompany/ModalInfoCompany";


export const AdminCompany = () => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const [client, setClient] = useState<Client>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!user) return;
    loadClient();
  }, [user, user?.token]);

  async function loadClient() {
    if (!user || !user.current_client) return;

    let findedClient = user.clients?.find(c => c.id === user.current_client);
    if (findedClient) setClient(findedClient);
    else toast.error('Empresa não encontrada');
  }

  return (
    <Wrapper
      asideActive={['Admin Empresa']}
      breadcrumbs={[{ name: 'Admin Empresa', href: '#' }]}
      module_name="Admin Panel"
      isAdmin
    >
      {(!user || !client) ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl" /> : (
        <div>
          <div className="mt-6">
            <div className="px-4 sm:px-0 flex justify-between">
              <div className="flex">
                <button
                  type="button"
                  className={`
                    text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
                    hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                    rounded-lg text-sm 
                    px-3 pt-1.5 pb-1.5 text-center mb-2 mt-auto
                  `}
                  onClick={() => setIsModalOpen(true)}
                ><PencilIcon w={17} />Editar Informações</button>
              </div>
            </div>
            <div className="flex gap-4 flex-col lg:flex-row mt-2">
              <div className={`
                flex rounded-lg !bg-primary-300/10 backdrop-blur-[25px]
                shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
                mb-6 w-full lg:w-[24rem] xl:w-[28rem] min-h-[15rem] py-6 text-primary-800
              `}>
                <div className="flex items-center justify-between gap-2 px-5 mb-2">
                  <h5 className="font-semibold dark:text-white">Informações da Empresa</h5>
                </div>
                <div className="flex flex-col gap-4 px-5">
                  <div className="flex flex-col">
                    <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Nome fantasia</strong>
                    <span className="text-sm text-gray-600">{client.nome_fantasia}</span>
                  </div>
                  <div className="flex flex-col">
                    <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">CNPJ</strong>
                    <span className="text-sm text-gray-600">{client.cnpj}</span>
                  </div>
                  <div className="flex flex-col">
                    <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Razão Social</strong>
                    <span className="text-sm text-gray-600">{client.razao_social}</span>
                  </div>
                  {client.economic_group && (
                    <div className="flex flex-col">
                      <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Grupo Econômico</strong>
                      <span className="text-sm text-gray-600">{client.economic_group}</span>
                    </div>
                  )}
                  {client.dedicated_server && (
                    <div className="flex flex-col">
                      <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Servidor Dedicado</strong>
                      <span className="text-sm text-gray-600">{client.dedicated_server}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className={`
                flex rounded-lg !bg-primary-300/10 backdrop-blur-[25px]
                shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
                mb-6 w-full lg:w-[24rem] xl:w-[28rem] min-h-[15rem] py-6 text-primary-800
              `}>
                <div className="flex items-center justify-between gap-2 px-5 mb-2">
                  <h5 className="font-semibold dark:text-white">Contato</h5>
                </div>
                <div className="flex flex-col gap-4 px-5">
                  <div>
                    <div className="flex gap-4">
                      <div className="flex flex-col">
                        <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">DDD</strong>
                        <span className="text-sm text-gray-600">{client.ddd ? client.ddd : client.telefone ? (
                          <small className="text-gray-400">--</small>
                        ):''}</span>
                      </div>
                      <div className="flex flex-col">
                        <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Telefone</strong>
                        <span className="text-sm text-gray-600">{client.telefone ? client.telefone : client.ddd ? (
                          <small className="text-gray-400">-- não informado --</small>
                        ):''}</span>
                      </div>
                    </div>
                    {(!client.telefone && !client.ddd) && (
                      <span className="text-sm">
                        <small className="text-gray-400">-- não informado --</small>
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Email</strong>
                    <span className="text-sm text-gray-600">{client.email ? client.email : (
                      <small className="text-gray-400">-- não informado --</small>
                    )}</span>
                  </div>
                </div>
              </div>
              
              <div className={`
                flex rounded-lg !bg-primary-300/10 backdrop-blur-[25px]
                shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
                mb-6 w-full lg:w-[24rem] xl:w-[28rem] min-h-[15rem] py-6 text-primary-800
              `}>
                <div className="flex items-center justify-between gap-2 px-5 mb-2">
                  <h5 className="font-semibold dark:text-white">Endereço</h5>
                </div>
                <div className="flex flex-col gap-4 px-5 h-full">
                  {(!client.cep && !client.logradouro && !client.bairro && !client.cidade && !client.estado) ? (
                    <div className="flex items-center justify-center text-center flex-1">
                      <small className="text-gray-400 pb-6">-- não informado --</small>
                    </div>
                  ):(
                    <>
                      <div className="flex gap-6">
                        
                        <div className="flex flex-col">
                          <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">CEP</strong>
                          <span className="text-sm text-gray-600">{client.cep}</span>
                        </div>
                      </div>
                      <div className="flex gap-6">
                        <div className="flex flex-col">
                          <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Logradouro</strong>
                          <span className="text-sm text-gray-600">{client.logradouro}</span>
                        </div>
                        
                        <div className="flex flex-col">
                          <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Número</strong>
                          <span className="text-sm text-gray-600">{client.numero}</span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                          <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Bairro</strong>
                          <span className="text-sm text-gray-600">{client.bairro}</span>
                        </div>
                      <div className="flex gap-6">
                        <div className="flex flex-col">
                          <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Cidade</strong>
                          <span className="text-sm text-gray-600">{client.cidade}</span>
                        </div>
                        <div className="flex flex-col">
                          <strong className="text-[10px] leading-none font-bold uppercase text-primary-900/80">Estado</strong>
                          <span className="text-sm text-gray-600">{client.estado}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ModalInfoCompany isOpen={isModalOpen} setIsOpen={setIsModalOpen} client={client} />
        </div>
      )}
    </Wrapper>
  );
}