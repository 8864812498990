import React, { useEffect, useState } from "react";
import { useNotify } from "../../contexts/NotifyContext";
import { cnpjMask, phoneMask } from "../../services/mask";
import { Client, PossiblePermissions } from "../../shared-types";
import { updateClient } from "../../services/client";
import { useAuth } from "../../contexts/AuthContext";
import { Modal } from "../../shared-components/utils/Modal";
import { InfoIcon } from "../../shared-components/utils/icons";
import { AddressIcon, ContactIcon } from "../SvgIcons";
import { api, handleErrorResultAndResponse, headerBearer } from "../../services/api";
import { CircularProgressbar } from "react-circular-progressbar";

interface UploadPictureType{
  file?: File,
  name?: string,
  preview?: string,
  progress: number,
  uploaded: boolean,
  error: boolean,
}
interface ModalComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  client: Client;
}
const INITIAL_UPLOAD_STATE = { progress: 0, uploaded: false, error: false }
export const ModalInfoCompany = ({ isOpen, setIsOpen, client }: ModalComponentProps) => {
  const { user, updateClient: updateClientContext } = useAuth();
  const { toast } = useNotify();

  const [currentStep, setCurrentStep] = useState(1)

  const [picture, setPicture] = useState(user?.picture ?? '');
  const [upload, setUpload] = useState<UploadPictureType>(INITIAL_UPLOAD_STATE)

  const [nomeFantasia, setNomeFantasia] = useState(client.nome_fantasia);
  const [razaoSocial, setRazaoSocial] = useState(client.razao_social);
  const [cnpj, setCnpj] = useState(client.cnpj);
  const [economicGroup, setEconomicGroup] = useState(client.economic_group);
  const [ddd, setDdd] = useState(client.ddd);
  const [telefone, setTelefone] = useState(client.telefone);
  const [email, setEmail] = useState(client.email);
  const [cep, setCep] = useState(client.cep);
  const [logradouro, setLogradouro] = useState(client.logradouro);
  const [numero, setNumero] = useState(client.numero);
  const [bairro, setBairro] = useState(client.bairro);
  const [cidade, setCidade] = useState(client.cidade);
  const [estado, setEstado] = useState(client.estado);
  const [dedicatedServer, setDedicatedServer] = useState(client.dedicated_server);

  useEffect(() => {
    setPicture(client.picture ?? '')
    setUpload(INITIAL_UPLOAD_STATE)
    setNomeFantasia(client.nome_fantasia);
    setRazaoSocial(client.razao_social);
    setCnpj(client.cnpj);
    setEconomicGroup(client.economic_group);
    setDdd(client.ddd);
    setTelefone(client.telefone);
    setEmail(client.email);
    setCep(client.cep);
    setLogradouro(client.logradouro);
    setNumero(client.numero);
    setBairro(client.bairro);
    setCidade(client.cidade);
    setEstado(client.estado);
  }, [client])

  //#region HANDLE PICTURE
  function handleProfilePicture(target: HTMLInputElement){
    if(!target.files || target.files.length === 0) return;

    const uploadPicture : UploadPictureType = {
      file: target.files[0],
      name: target.files[0].name,
      preview: URL.createObjectURL(target.files[0]),
      progress: 0,
      uploaded: false,
      error: false,
    };
    setUpload(uploadPicture)

    processUpload(uploadPicture)
  }
  async function processUpload(uploadedFile: UploadPictureType){
    if(!uploadedFile.file) return;
    if(!user) return;
    
    const data = new FormData();

    data.append('file', uploadedFile.file, uploadedFile.name);
    data.append('external_id', user.id);
    data.append('name', 'Outros');
    data.append('type', 'personal');
    data.append('description', `Atualização da foto de perfil`);
    
    try{
      const { data: response } = await api.post("/gallery", data, {
        ...headerBearer(user.token),
        onUploadProgress: e => {
          const progress = e.total ? Math.round(
            (e.loaded * 100) / e.total
          ) : 0;
  
          setUpload((prevState) => ({
            ...prevState,
            progress
          }));
        }
      });
    
      if(!response.result) throw new Error(
        response.response
      );
  
      const url = `${process.env.REACT_APP_BASE_URL}${response.data.src}`;
      setUpload((prevState) => ({
        ...prevState,
        uploaded: true,
        id: response.data.id,
        url,
      }));

      setPicture(url);
    }catch(err){
      const error = handleErrorResultAndResponse(err, {
        result: false,
        response: `Não foi possível fazer o upload do arquivo ${uploadedFile.name}`
      });

      toast.error(error.response);

      setUpload((prevState) => ({
        ...prevState,
        error: true
      }));
    }
  }
  //#endregion HANDLE PICTURE
  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>){
    if(e) e.preventDefault();

    if(!user) return;

    const validateStepOne = () => {
      if(!(nomeFantasia && razaoSocial && cnpj)){
        toast.error('Todos os campos são obrigatórios');
        return false;
      }

      return true;
    }
    const validateStepTwo = () => {
      if((telefone && !ddd) || (ddd && !telefone)){
        toast.error('Para cadastrar o telefone é obrigatório preencher os campos telefone e DDD');
        return false;            
      }

      return true;
    }
    const validateStepThree = () => {
      if(cep || logradouro || numero || bairro || cidade || estado){
        if(!(cep && logradouro && numero && bairro && cidade && estado)){
          toast.error('Para cadastrar o endereço é obrigatório preencher todos os campos')

          return false;
        }
      }

      return true;                
    }

    if(currentStep === 1){
      if(!validateStepOne()) return;
      
      setCurrentStep(2)
      return;
    }
    if(currentStep === 2){
      if(!validateStepOne()){
        setCurrentStep(1);
        return;
      }
      if(!validateStepTwo()) return;

      setCurrentStep(3)
      return;
    }
    if(currentStep === 3){
      if(!validateStepOne()){
        setCurrentStep(1);
        return;
      }
      if(!validateStepTwo()){
        setCurrentStep(2);
        return;
      }

      if (!validateStepThree()) return;

      const data = await updateClient(user.token, {
        nome_fantasia: nomeFantasia,
        razao_social: razaoSocial,
        picture,
        cnpj,
        economic_group: economicGroup,
        ddd,
        telefone,
        email,
        cep,
        logradouro,
        numero,
        bairro,
        cidade,
        estado,
        ...(user?.permitions_slug?.includes(PossiblePermissions.ADMIN_HUB) ? {
          dedicated_server: dedicatedServer
        }:{})
      })

      if(!data.result){
        toast.error(data.response)
        return;
      }

      if(!data.data){
        toast.warning('Sem informações suficientes para lidar com a resposta de atualização')
        return;
      }

      toast.success(data.response)
      
      updateClientContext(data.data)

      setIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{
        title: "",
        size: "sm:w-full sm:max-w-xl",
        cancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonFn: () => {
          if(currentStep === 1) setIsOpen(false)
          else setCurrentStep(currentStep - 1)
        },
        actionButton: {
          theme: "primary",
          text: currentStep === 3 ? 'Concluir' : 'Próximo passo',
          onClick: () => handleSubmit(),
          autoClose: false,
        },
      }}
    >
      <ol className="flex justify-between items-center w-full -mt-2">
        <li className={`
          flex w-full items-center
          after:w-full after:h-1 
          after:border-b after:border-gray-100 after:border-4 after:inline-block 
          dark:after:border-gray-700 
          ${currentStep > 1 ? 'after:border-b after:border-primary-500 after:border-4' : 'after:border-gray-200'}
        `}>
          <div 
            className={`flex items-center justify-center w-8 h-8 rounded-full lg:h-8 lg:w-8 shrink-0 cursor-pointer ${
              currentStep >= 1 ? 'bg-primary-500 text-white' : 'bg-gray-100 text-blue-600'}`
            }
            onClick={() => setCurrentStep(1)}
          >
            <InfoIcon w={18} h={18}/>
          </div>
        </li>
        <li className={`
          flex w-full items-center 
          after:w-full after:h-1 after:border-b after:border-gray-100 
          after:border-4 after:inline-block dark:after:border-gray-700 
          ${currentStep > 2 ? 'after:border-b after:border-primary-500 after:border-4' : 'after:border-gray-200'}`}>
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full lg:h-8 lg:w-8 shrink-0 cursor-pointer ${
              currentStep >= 2 ? 'bg-primary-500 text-white' : 'bg-gray-100 text-blue-600'
            }`}
            onClick={() => setCurrentStep(2)}
          >
            <ContactIcon width={18} height={18}/>
          </div>
        </li>
        <li className="flex items-center">
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full lg:h-8 lg:w-8 shrink-0 cursor-pointer ${
              currentStep === 3 ? 'bg-primary-500 text-white' : 'bg-gray-100 text-blue-600'
            }`}
            onClick={() => setCurrentStep(3)}
          >
            <AddressIcon width={18} height={18}/>
          </div>
        </li>
      </ol>
      <form className="pt-2" method="POST" onSubmit={handleSubmit}>
        {currentStep === 1 ? (
          <div>
            <div className="flex items-center pb-4 gap-4 mt-3">
              <label className="relative">
                <CircularProgressbar
                  styles={{
                    root: { width: 58 },
                    path: { stroke: upload.uploaded ? "rgba(5,150,105,.8)" : upload.error ? "rgb(249,108,108)" : "#225397" },
                    trail:{ stroke: "#ccc" }
                  }}
                  strokeWidth={10}
                  value={upload.progress}
                />
                {(upload.preview || picture) ? (
                  <img
                    className="h-[50px] w-[50px] rounded-full shadow object-cover absolute top-[4px] left-[4px]"
                    src={upload.preview && !upload.error && !upload.uploaded ? upload.preview : picture}
                    alt={"Foto de perfil"}
                  />
                ):(<div className="h-[50px] w-[50px] rounded-full shadow absolute top-[4px] left-[4px]"/>)}
                <input
                  type="file"
                  accept="*/image"
                  className="hidden"
                  onChange={(e) => handleProfilePicture(e.target)}
                />
              </label>
              <div>
                <h5 className="block mb-1 text-lg leading-none font-semibold">
                  Editar Informações
                </h5>
                <span className="block text-xs leading-snug text-gray-400 w-80">
                  Clique na imagem para poder atualizar a foto da empresa, depois clique em próximo passo até concluir todas etapas
                </span>
              </div>
            </div>
            <div className={`grid gap-4 mt-2 mb-4 sm:grid-cols-2`}>
              <div>
                <div className="mb-1">
                  <label htmlFor="nome-fantasia" className="text-sm font-medium dark:text-gray-300 block text-gray-700">Nome Fantasia</label>
                </div>
                <input type="text" name="nome-fantasia" id="nome-fantasia" value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Empresa" required />
              </div>
              <div>
                <div className="mb-1">
                  <label htmlFor="razao-social" className="text-sm font-medium dark:text-gray-300 block text-gray-700">Razão Social</label>
                </div>
                <input type="text" name="razao-social" id="razao-social" value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Empresa LTDA" required />
              </div>
              <div>
                <div className="mb-1">
                  <label htmlFor="cnpj" className="text-sm font-medium dark:text-gray-300 block text-gray-700">CNPJ</label>
                </div>
                <input
                  type="text"
                  name="cnpj"
                  id="cnpj"
                  value={cnpjMask(cnpj)}
                  onChange={(e) => setCnpj(cnpjMask(e.target.value))}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="00.000.000/0000-00"
                  required
                />
              </div>
              <div>
                <div className="mb-1">
                  <label htmlFor="grupo-economico" className="text-sm font-medium dark:text-gray-300 block text-gray-700">Grupo Econômico</label>
                </div>
                <input
                  type="text"
                  name="grupo-economico"
                  id="grupo-economico"
                  value={economicGroup}
                  onChange={(e) => setEconomicGroup(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Digite o grupo econômico"
                  required
                />
              </div>
              {user?.permitions_slug?.includes(PossiblePermissions.ADMIN_HUB) && (
                <div className="col-span-2">
                  <div className="mb-1">
                    <label htmlFor="dedicated-server" className="text-sm font-medium dark:text-gray-300 block text-gray-700">Servidor Dedicado (opcional)</label>
                  </div>
                  <input
                    type="url"
                    name="dedicated-server"
                    id="dedicated-server"
                    value={dedicatedServer}
                    onChange={(e) => setDedicatedServer(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Digite a url do servidor dedicado"              
                  />

                  <span className="text-xs text-gray-500 block leading-4 mt-1">
                    - Deve ser a url do hub (https://hub.<span className="text-gray-400">url_dedicada.com</span>)<br/>
                    - A URL não pode ter barra no final<br/>
                    - Para o funcionamento correto da funcionalidade, a url do isac, deve ser exatamente igual a do hub, porém sem o prefix 'hub.'
                  </span>
                </div>
              )}
            </div>
          </div>
        ):currentStep === 2 ? (
          <div className={`grid gap-4 mt-4 mb-4 sm:grid-cols-2`}>
            <div>
              <label htmlFor="ddd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">DDD</label>
              <input
                type="text"
                name="ddd"
                id="ddd"
                maxLength={3}
                value={ddd}
                onChange={(e) => setDdd(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="DDD"
              />
            </div>
            <div>
              <label htmlFor="telefone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telefone</label>
              <input
                type="text"
                name="telefone"
                id="telefone"
                value={phoneMask(telefone)}
                onChange={(e) => setTelefone(phoneMask(e.target.value))}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="99999-9999"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="empresa@empresa.com.br" />
            </div>
          </div>
        ):currentStep === 3 ? (
          <div className={`grid gap-4 mt-4 mb-4 sm:grid-cols-2`}>
            <div>
              <label htmlFor="cep" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">CEP</label>
              <input type="text" name="cep" id="cep" value={cep} onChange={(e) => setCep(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10000-000" />
            </div>
            <div>
              <label htmlFor="logradouro" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Logradouro</label>
              <input type="text" name="logradouro" id="logradouro" value={logradouro} onChange={(e) => setLogradouro(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Rua Exemplo" />
            </div>
            <div>
              <label htmlFor="numero" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Número</label>
              <input type="number" name="numero" id="numero" value={numero} onChange={(e) => setNumero(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="100" />
            </div>
            <div>
              <label htmlFor="bairro" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bairro</label>
              <input type="text" name="bairro" id="bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Bairro" />
            </div>
            <div>
              <label htmlFor="cidade" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cidade</label>
              <input type="text" name="cidade" id="cidade" value={cidade} onChange={(e) => setCidade(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Cidade" />
            </div>
            <div>
              <label htmlFor="estado" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Estado</label>
              <input type="text" name="estado" id="estado" value={estado} onChange={(e) => setEstado(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Estado" />
            </div>
          </div>
        ):<></>}
      </form>
    </Modal>
  );
};
